module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Space Coast Tech Club","short_name":"SCTC","start_url":"/","background_color":"#6610f2","theme_color":"#6610f2","display":"minimal-ui","icon":"src/images/sctc-logo.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147453384-1","head":false,"respectDNT":true,"cookieDomain":"spacecoasttech.club"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
